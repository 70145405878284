<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                Add Column For Mountain Table
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <form>
                    <v-row>
                        <v-col cols="12"
                        >
                            <v-text-field
                                    label="Column Name"
                                    v-model="mountainData.NewColumnName"
                                    :error-messages="mountainNameErrors"
                                    required
                                    @input="$v.mountainData.NewColumnName.$touch()"
                                    @blur="$v.mountainData.NewColumnName.$touch()"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        NewColumnName: "createMountainForm",
        props: ["success"],
        computed: {
            mountainNameErrors() {
                const errors = [];
                if (!this.$v.mountainData.NewColumnName.$dirty) return errors;
                !this.$v.mountainData.NewColumnName.required &&
                errors.push("Column Name is required.");
                return errors;
            },
        },
        data() {
            return {
                created: false,
                mountainData: {},
                companyList: [],
                snackbar: {
                    dateMenu: false,
                    servicesData: {
                        data: {},
                        message: "",
                        color: ""
                    },
                }
            };
        },
        mounted() {

        },
        validations: {
            mountainData: {
                NewColumnName: {required},
            }
        },
        methods: {
            dialogueClose() {
                this.mountainData = {};
                this.$emit("dialogueClose");
            },
            formResponse() {
                this.$emit("formResponse", this.snackbar.servicesData);
                this.dialogueClose();
            },
            submit() {
                console.log(this.mountainData)
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Report/AddTrekkingAsync", this.mountainData).then(response => {
                        if (response.data.success) {
                            this.snackbar.servicesData.data = response.data
                            this.snackbar.servicesData.message = " Column added Successfully"
                            this.snackbar.servicesData.color = "success"
                            this.formResponse()
                        } else {
                            this.snackbar.servicesData.data = response.data
                            this.snackbar.servicesData.message = response.data.errors[0]
                            this.snackbar.servicesData.color = "error"
                            this.formResponse()
                        }
                    }).catch(response => {
                        this.snackbar.servicesData.data = response.data
                        this.snackbar.servicesData.message = "Error Adding Column, Please Contact Admin"
                        this.snackbar.servicesData.color = "error"
                        this.formResponse()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.mountainData = {};
            },
        }
    };
</script>

<style scoped>
</style>